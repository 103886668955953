var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "index-page",
    class: _vm.$mq
  }, [_c('img', {
    staticClass: "index-page__background",
    class: _vm.$mq,
    attrs: {
      "src": require(`@/assets/images/partner_bg.svg`)
    }
  }), _c('div', {
    staticClass: "lights"
  }, [_c('svg', {
    attrs: {
      "width": "1440",
      "height": "770",
      "viewBox": "0 0 1440 770",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    attrs: {
      "opacity": "0.5",
      "filter": "url(#filter0_f_1422_8206)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M588.078 227.574C588.078 227.574 622.205 307.177 612.14 336.215C602.076 365.253 309.789 448.779 286.111 440.573C262.434 432.366 261.838 361.584 271.902 332.546C281.966 303.508 588.078 227.574 588.078 227.574Z",
      "fill": "#A09EFF",
      "fill-opacity": "0.85"
    }
  })]), _c('g', {
    attrs: {
      "opacity": "0.5",
      "filter": "url(#filter1_f_1422_8206)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M1413.08 -93.4255C1413.08 -93.4255 1447.2 -13.823 1437.14 15.2152C1427.08 44.2533 1134.79 127.779 1111.11 119.573C1087.43 111.366 1086.84 40.584 1096.9 11.5459C1106.97 -17.4923 1413.08 -93.4255 1413.08 -93.4255Z",
      "fill": "#FFECF3",
      "fill-opacity": "0.85"
    }
  })]), _c('defs', [_c('filter', {
    attrs: {
      "id": "filter0_f_1422_8206",
      "x": "-62.2729",
      "y": "-100.426",
      "width": "1004.27",
      "height": "869.562",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "BackgroundImageFix",
      "result": "shape"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "164",
      "result": "effect1_foregroundBlur_1422_8206"
    }
  })], 1), _c('filter', {
    attrs: {
      "id": "filter1_f_1422_8206",
      "x": "762.727",
      "y": "-421.426",
      "width": "1004.27",
      "height": "869.562",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "BackgroundImageFix",
      "result": "shape"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "164",
      "result": "effect1_foregroundBlur_1422_8206"
    }
  })], 1)])])]), _c('public-header'), _c('section', {
    staticClass: "hero",
    class: _vm.$mq
  }, [_c('div', {
    staticClass: "hero__left",
    class: _vm.$mq
  }, [_c('h1', {
    staticClass: "h1 hero__title",
    class: _vm.$mq
  }, [_vm._v(" Партнерская программа ")]), _vm._m(0), _c('div', {
    staticClass: "hero__action"
  }, [_c('PButton', {
    staticClass: "hero__action__button",
    class: _vm.$mq,
    on: {
      "click": _vm.onCtaClick
    }
  }, [_vm.$store.state.user.user ? [_vm._v(" Получить ссылку ")] : [_vm._v(" Создать аккаунт и получить ссылку ")]], 2)], 1)]), _c('div', {
    staticClass: "hero__right"
  }, [_c('div', {
    staticClass: "hero__image",
    class: _vm.$mq
  }, [_c('img', {
    staticClass: "hero__image-content",
    class: _vm.$mq,
    attrs: {
      "width": "699",
      "height": "425",
      "src": require(`./img/hero.png`),
      "srcset": `${require(`./img/hero@2x.png`)} 2x`
    }
  })])])]), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('h2', {
    staticClass: "h2 section__title",
    class: _vm.$mq
  }, [_vm._v(" Начать зарабатывать очень просто ")]), _c('div', {
    staticClass: "steps-wrapper"
  }, [_c('Steps')], 1)])]), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('h2', {
    staticClass: "h2 section__title",
    class: _vm.$mq
  }, [_vm._v(" Для кого подходит партнерская программа? ")]), _c('div', {
    staticClass: "steps-wrapper"
  }, [_c('ForWhom')], 1)])]), _c('section', {
    staticClass: "section section-last"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('h2', {
    staticClass: "h2 section__title",
    class: _vm.$mq
  }, [_vm._v(" Условия партнерской программы ")]), _c('div', {
    staticClass: "faq-wrapper"
  }, [_c('PFaq', {
    scopedSlots: _vm._u([{
      key: "1:question",
      fn: function () {
        return [_vm._v(" Какие типы трафика можно использовать? ")];
      },
      proxy: true
    }, {
      key: "1:answer",
      fn: function () {
        return [_c('p', [_vm._v(" Для распространения вашей партнерской ссылки разрешены следующие каналы: email-рассылки, публикации в социальных сетях и сообществах в мессенджерах, Youtube, обучающие курсы, личный или корпоративный блог, контекстная реклама (кроме таргетинга на бренд “salesfinder” в любых формах). ")])];
      },
      proxy: true
    }, {
      key: "2:question",
      fn: function () {
        return [_vm._v(" Как работает партнерская ссылка? ")];
      },
      proxy: true
    }, {
      key: "2:answer",
      fn: function () {
        return [_c('p', [_vm._v(" Когда пользователь переходит по партнерской ссылке - мы сохраняем партнерскую метку (cookie) для этого пользователя на 90 дней. Если в течение этого периода он создаст аккаунт в сервисе, этот аккаунт будет навсегда закреплен за партнером. С каждого платежа этого клиента (первый платеж + все продления) партнер получает вознаграждение. ")]), _c('p', [_vm._v(" Мы работаем по модели Last Cookie Wins - если клиент перешел по нескольким партнерским ссылкам и зарегистрировался, он будет привязан к последнему партнеру. ")])];
      },
      proxy: true
    }, {
      key: "3:question",
      fn: function () {
        return [_vm._v(" Условия вывода средств ")];
      },
      proxy: true
    }, {
      key: "3:answer",
      fn: function () {
        return [_c('p', [_vm._v(" Вывод средств доступен на расчётный счет - для юридических лиц, ИП и самозанятых, банковские карты"), _vm._v(" - для физических лиц. ")]), _c('p', [_vm._v(" Минимальная сумма для вывода - 3000 руб, вывод активируется при достижении этой суммы. Вывод на счета физических лиц - комиссия 16%, для юридических лиц, ИП и самозанятых - без комиссии. ")])];
      },
      proxy: true
    }, {
      key: "4:question",
      fn: function () {
        return [_vm._v(" Какие каналы и механики запрещены? ")];
      },
      proxy: true
    }, {
      key: "4:answer",
      fn: function () {
        return [_c('ul', [_c('li', [_vm._v("Купонные сайты")]), _c('li', [_vm._v(" Авторефералы (партнер и клиент - одно лицо) ")]), _c('li', [_vm._v(" Контекстная реклама на бренд SalesFinder (в любых формах) ")]), _c('li', [_vm._v(" Мотивация пользователей разделением вашей партнерской комиссии ")]), _c('li', [_vm._v("СПАМ-рассылки")])]), _c('p', [_vm._v(" Обратите внимание на эти ограничения, при нарушении условий партнерской программы - ваш партнерский баланс может быть заблокирован без предварительного уведомления. ")])];
      },
      proxy: true
    }, {
      key: "5:question",
      fn: function () {
        return [_vm._v(" У меня есть своя база учеников⁠/⁠клиентов. Можно получить специальные условия? ")];
      },
      proxy: true
    }, {
      key: "5:answer",
      fn: function () {
        return [_c('p', [_vm._v(" Разумеется! Мы всегда рады предложить вашим клиентам или ученикам дополнительные бонусы при подключении. "), _c('router-link', {
          attrs: {
            "to": {
              name: 'Contact'
            }
          }
        }, [_vm._v("Свяжитесь с нами")]), _vm._v(" для обсуждения деталей сотрудничества ")], 1)];
      },
      proxy: true
    }])
  })], 1)])]), _c('public-footer')], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "hero__desc"
  }, [_c('span', {
    staticClass: "highlight"
  }, [_vm._v("Зарабатывайте")]), _vm._v(" вместе с нами. "), _c('span', {
    staticClass: "highlight"
  }, [_vm._v("Получайте 20%")]), _vm._v(" с каждого привлеченного пользователя! ")]);
}];
export { render, staticRenderFns };