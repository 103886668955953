import "core-js/modules/es.array.push.js";
import PublicHeader from "../Header";
import PublicFooter from "../Footer";
import Steps from "./Steps";
import ForWhom from "./ForWhom";
export default {
  meta: {
    title: "Партнерская программа",
    meta: [{
      name: "description",
      content: "SalesFinder - Поиск прибыльных ниш, анализ цен и продаж конкурентов, трекинг позиций, данные по брендам и продавцам. Удобная аналитика маркетплейсов для увеличения ваших продаж!"
    }, {
      name: "viewport",
      content: "width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0"
    }]
  },
  components: {
    PublicHeader,
    PublicFooter,
    Steps,
    ForWhom
  },
  methods: {
    onCtaClick() {
      if (this.$store.state.user.user) {
        this.$router.push({
          name: "ProfilePartner"
        });
      } else {
        this.$router.push({
          name: "Register",
          params: {
            backRoute: {
              name: "ProfilePartner"
            }
          }
        });
      }
    }
  }
};