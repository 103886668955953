var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('svg', {
    attrs: {
      "width": "207",
      "height": "207",
      "viewBox": "0 0 207 207",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('circle', {
    attrs: {
      "opacity": "0.04",
      "cx": "103.5",
      "cy": "103.5",
      "r": "103.5",
      "fill": "#9C9AFF"
    }
  }), _c('g', {
    attrs: {
      "opacity": "0.08"
    }
  }, [_c('path', {
    attrs: {
      "d": "M84.6353 78.348H72.314C69.3977 78.348 67.0335 80.6405 67.0335 83.4685V132.967C67.0335 135.795 69.3977 138.088 72.314 138.088H84.6353C87.5517 138.088 89.9159 135.795 89.9159 132.967V83.4685C89.9159 80.6405 87.5517 78.348 84.6353 78.348Z",
      "fill": "#9C9AFF"
    }
  }), _c('path', {
    attrs: {
      "d": "M137.442 66.4001H125.12C122.204 66.4001 119.84 68.6926 119.84 71.5206V132.967C119.84 135.795 122.204 138.088 125.12 138.088H137.442C140.358 138.088 142.722 135.795 142.722 132.967V71.5206C142.722 68.6926 140.358 66.4001 137.442 66.4001Z",
      "fill": "#9C9AFF"
    }
  }), _c('path', {
    attrs: {
      "d": "M111.037 52.7452H98.7162C95.7998 52.7452 93.4356 55.0377 93.4356 57.8657V132.967C93.4356 135.795 95.7998 138.088 98.7162 138.088H111.037C113.954 138.088 116.318 135.795 116.318 132.967V57.8657C116.318 55.0377 113.954 52.7452 111.037 52.7452Z",
      "fill": "#9C9AFF"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M16.918 85.1755C16.918 81.9028 19.6539 79.2498 23.0288 79.2498H44.151C47.0782 79.2498 49.5943 81.2627 50.1505 84.0495L60.6568 136.687C61.9377 140.789 64.4537 142.403 67.0334 142.403H142.662C142.701 142.391 143.238 142.281 144.144 141.454C145.158 140.528 146.377 138.91 147.403 136.404L158.743 83.9594C159.336 81.2151 161.832 79.2498 164.724 79.2498H184.966C188.341 79.2498 191.077 81.9028 191.077 85.1755C191.077 88.4481 188.341 91.1012 184.966 91.1012H169.686L159.263 139.304C159.2 139.598 159.113 139.887 159.004 140.169C157.421 144.263 155.194 147.643 152.52 150.084C149.904 152.472 146.499 154.255 142.721 154.255H67.0334C57.339 154.255 51.0989 147.395 48.8364 139.716C48.7877 139.55 48.7464 139.383 48.7126 139.214L39.1095 91.1012H23.0288C19.6539 91.1012 16.918 88.4481 16.918 85.1755Z",
      "fill": "#9C9AFF"
    }
  })])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };