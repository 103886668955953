var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "steps",
    class: _vm.$mq
  }, [_c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "circle"
  }, [_vm._v("1")]), _c('CartIcon', {
    staticClass: "cart-icon"
  }), _c('div', {
    staticClass: "spacer"
  }), _vm._m(0)], 1), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "circle"
  }, [_vm._v("2")]), _c('CartIcon', {
    staticClass: "cart-icon"
  }), _c('div', {
    staticClass: "spacer"
  }), _vm._m(1)], 1), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "circle"
  }, [_vm._v("3")]), _c('CartIcon', {
    staticClass: "cart-icon"
  }), _c('div', {
    staticClass: "spacer"
  }), _c('div', {
    staticClass: "content"
  }, [_vm._v(" Получайте пожизненное вознаграждение с каждого привлеченного пользователя ")])], 1)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "content"
  }, [_vm._v(" Создайте бесплатный аккаунт"), _c('br'), _vm._v(" в сервисе для участия"), _c('br'), _vm._v(" в партнерской программе ")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "content"
  }, [_vm._v(" Расскажите о SalesFinder:"), _c('br'), _vm._v(" поделитесь своей партнерской ссылкой в блоге, канале или обучающем курсе ")]);
}];
export { render, staticRenderFns };