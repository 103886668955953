var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "for-whom",
    class: _vm.$mq
  }, [_c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('svg', {
    attrs: {
      "width": "64",
      "height": "64",
      "viewBox": "0 0 64 64",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M33 20C34.4145 20 35.771 20.5619 36.7712 21.5621C37.7714 22.5623 38.3333 23.9188 38.3333 25.3333H33V20ZM33 12C36.5362 12 39.9276 13.4048 42.4281 15.9052C44.9286 18.4057 46.3333 21.7971 46.3333 25.3333H41C41 23.2116 40.1571 21.1768 38.6569 19.6765C37.1566 18.1762 35.1217 17.3333 33 17.3333V12ZM33 4C38.658 4 44.0842 6.24761 48.0849 10.2484C52.0857 14.2492 54.3333 19.6754 54.3333 25.3333H49C49 21.0899 47.3143 17.0202 44.3137 14.0196C41.3131 11.019 37.2435 9.33333 33 9.33333V4Z",
      "fill": "#D1D0FF"
    }
  }), _c('path', {
    attrs: {
      "d": "M27.6667 6.66666V30.6667H46.3333V57.3333C46.3333 58.0406 46.0524 58.7188 45.5523 59.2189C45.0522 59.719 44.3739 60 43.6667 60H11.6667C10.9594 60 10.2811 59.719 9.78105 59.2189C9.28095 58.7188 9 58.0406 9 57.3333V9.33332C9 8.62608 9.28095 7.9478 9.78105 7.4477C10.2811 6.94761 10.9594 6.66666 11.6667 6.66666H27.6667Z",
      "fill": "#5F5CDD"
    }
  })])]), _c('div', {
    staticClass: "title"
  }, [_vm._v("Для агентств и сервис-провайдеров")]), _c('div', {
    staticClass: "content"
  }, [_vm._v(" Подключайте своих клиентов, получайте специальные условия партнерства. Для крупных агентств мы предлагаем индивидуальные функции и размер вознаграждения. "), _c('router-link', {
    attrs: {
      "to": {
        name: 'Contact'
      }
    }
  }, [_vm._v("Свяжитесь с нами")]), _c('br'), _vm._v(" для обсуждения деталей. ")], 1)]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('svg', {
    attrs: {
      "width": "64",
      "height": "64",
      "viewBox": "0 0 64 64",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('circle', {
    attrs: {
      "cx": "32",
      "cy": "32",
      "r": "32",
      "fill": "#5F5CDD"
    }
  }), _c('path', {
    attrs: {
      "d": "M26.1221 24.5863H22.852C22.0099 24.5863 21.3273 25.2528 21.3273 26.075V40.4654C21.3273 41.2876 22.0099 41.9541 22.852 41.9541H26.1221C26.9642 41.9541 27.6468 41.2876 27.6468 40.4654V26.075C27.6468 25.2528 26.9642 24.5863 26.1221 24.5863Z",
      "fill": "#D1D0FF"
    }
  }), _c('path', {
    attrs: {
      "d": "M41.6563 21.1127L38.4777 21.1128C37.6356 21.1128 36.953 21.7793 36.953 22.6014V40.4654C36.953 41.2876 37.6356 41.9541 38.4777 41.9541L41.6563 41.9541C42.4984 41.9541 43.181 41.2876 43.181 40.4654V22.6014C43.181 21.7792 42.4984 21.1127 41.6563 21.1127Z",
      "fill": "#D1D0FF"
    }
  }), _c('path', {
    attrs: {
      "d": "M34.0329 17.143H30.4753C29.6333 17.143 28.9507 17.8095 28.9507 18.6316V40.4654C28.9507 41.2876 29.6333 41.9541 30.4753 41.9541H34.0329C34.875 41.9541 35.5576 41.2876 35.5576 40.4654V18.6316C35.5576 17.8095 34.875 17.143 34.0329 17.143Z",
      "fill": "#D1D0FF"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M6.85718 26.5712C6.85718 25.6198 7.64713 24.8485 8.62159 24.8485H14.7203C15.5655 24.8485 16.292 25.4337 16.4526 26.2438L19.4861 41.5469C19.856 42.7393 20.5824 43.2087 21.3273 43.2087H43.1637C43.1752 43.2052 43.3302 43.173 43.5917 42.9326C43.8845 42.6636 44.2365 42.1931 44.5327 41.4645L47.807 26.2177C47.9783 25.4198 48.699 24.8485 49.5339 24.8485H55.3785C56.3529 24.8485 57.1429 25.6198 57.1429 26.5712C57.1429 27.5226 56.3529 28.2939 55.3785 28.2939H50.9667L47.9573 42.3076C47.9389 42.3931 47.9139 42.4772 47.8825 42.559C47.4253 43.7493 46.7823 44.7321 46.0103 45.4416C45.2549 46.1358 44.2719 46.6542 43.181 46.6542H21.3273C18.5281 46.6542 16.7264 44.6597 16.0732 42.4274C16.0591 42.3793 16.0471 42.3306 16.0374 42.2814L13.2647 28.2939H8.62159C7.64713 28.2939 6.85718 27.5226 6.85718 26.5712Z",
      "fill": "#D1D0FF"
    }
  })])]), _c('div', {
    staticClass: "title"
  }, [_vm._v("Для клиентов SalesFinder")]), _vm._m(0)]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('svg', {
    attrs: {
      "width": "64",
      "height": "64",
      "viewBox": "0 0 64 64",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M34.3333 46.8572V52.1905H45V57.5238H18.3333V52.1905H29V46.8572H7.66667C6.95942 46.8572 6.28115 46.5762 5.78105 46.0761C5.28095 45.576 5 44.8978 5 44.1905V9.52384C5 8.8166 5.28095 8.13832 5.78105 7.63823C6.28115 7.13813 6.95942 6.85718 7.66667 6.85718H55.6667C56.3739 6.85718 57.0522 7.13813 57.5523 7.63823C58.0524 8.13832 58.3333 8.8166 58.3333 9.52384V44.1905C58.3333 44.8978 58.0524 45.576 57.5523 46.0761C57.0522 46.5762 56.3739 46.8572 55.6667 46.8572H34.3333ZM26.3333 18.8572V34.8572L39.6667 26.8572L26.3333 18.8572Z",
      "fill": "#5F5CDD"
    }
  }), _c('path', {
    attrs: {
      "d": "M26.333 18.8572V34.8572L39.6663 26.8572L26.333 18.8572Z",
      "fill": "#D1D0FF"
    }
  })])]), _c('div', {
    staticClass: "title"
  }, [_vm._v("Для блогеров и создателей курсов")]), _vm._m(1)]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('svg', {
    attrs: {
      "width": "64",
      "height": "64",
      "viewBox": "0 0 64 64",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M7.66667 8H55.6667C56.3739 8 57.0522 8.28095 57.5523 8.78105C58.0524 9.28115 58.3333 9.95942 58.3333 10.6667V53.3333C58.3333 54.0406 58.0524 54.7189 57.5523 55.219C57.0522 55.7191 56.3739 56 55.6667 56H7.66667C6.95942 56 6.28115 55.7191 5.78105 55.219C5.28095 54.7189 5 54.0406 5 53.3333V10.6667C5 9.95942 5.28095 9.28115 5.78105 8.78105C6.28115 8.28095 6.95942 8 7.66667 8ZM31.6667 40V45.3333H47.6667V40H31.6667ZM22.104 32L14.5627 39.5413L18.3333 43.3147L29.648 32L18.3333 20.6853L14.5627 24.4587L22.104 32Z",
      "fill": "#5F5CDD"
    }
  }), _c('path', {
    attrs: {
      "d": "M31.6665 40V45.3333H47.6665V40H31.6665ZM22.1038 32L14.5625 39.5413L18.3332 43.3146L29.6478 32L18.3332 20.6853L14.5625 24.4586L22.1038 32Z",
      "fill": "#D1D0FF"
    }
  })])]), _c('div', {
    staticClass: "title"
  }, [_vm._v("Для разработчиков ПО и сервисов")]), _vm._m(2)])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "content"
  }, [_vm._v(" Лучше всего о сервисе могут рассказать сами клиенты. Поделитесь с друзьям и коллегами,"), _c('br'), _vm._v(" как именно SalesFinder помог вам в работе"), _c('br'), _vm._v(" на маркетплейсах. Они получат скидку,"), _c('br'), _vm._v(" а вы приятный бонус. ")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "content"
  }, [_vm._v(" Расскажите о SalesFinder своей аудитории"), _c('br'), _vm._v(" в блоге, Telegram или Youtube-канале. Добавьте информацию о сервисе в свои обучающие курсы⁠/⁠вебинары по старту и росту"), _c('br'), _vm._v(" на маркетплейсах. Для успешной работы вашим ученикам нужны эффективные инструменты. ")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "content"
  }, [_vm._v(" Создаете ПО для работы с маркетплейсами? Интегрируйте функционал SalesFinder через API"), _c('br'), _vm._v(" в свое приложение, дайте своим пользователям больше возможностей и получайте вознаграждение. ")]);
}];
export { render, staticRenderFns };